const IranProvinces = [
  {
    label: "آذربایجان شرقی",
    value: "آذربایجان شرقی",
  },
  {
    label: "آذربایجان غربی",
    value: "آذربایجان غربی",
  },
  {
    label: "اردبیل",
    value: "اردبیل",
  },
  {
    label: "اصفهان",
    value: "اصفهان",
  },
  {
    label: "البرز",
    value: "البرز",
  },
  {
    label: "ایلام",
    value: "ایلام",
  },
  {
    label: "بوشهر",
    value: "بوشهر",
  },
  {
    label: "تهران",
    value: "تهران",
  },
  {
    label: "چهارمحال و بختیاری",
    value: "چهارمحال و بختیاری",
  },
  {
    label: "خراسان جنوبی",
    value: "خراسان جنوبی",
  },
  {
    label: "خراسان رضوی",
    value: "خراسان رضوی",
  },
  {
    label: "خراسان شمالی",
    value: "خراسان شمالی",
  },
  {
    label: "خوزستان",
    value: "خوزستان",
  },
  {
    label: "زنجان",
    value: "زنجان",
  },
  {
    label: "سمنان",
    value: "سمنان",
  },
  {
    label: "سیستان و بلوچستان",
    value: "سیستان و بلوچستان",
  },
  {
    label: "فارس",
    value: "فارس",
  },
  {
    label: "قزوین",
    value: "قزوین",
  },
  {
    label: "قم",
    value: "قم",
  },
  {
    label: "کردستان",
    value: "کردستان",
  },
  {
    label: "کرمان",
    value: "کرمان",
  },
  {
    label: "کرمانشاه",
    value: "کرمانشاه",
  },
  {
    label: "کهگیلویه و بویراحمد",
    value: "کهگیلویه و بویراحمد",
  },
  {
    label: "گلستان",
    value: "گلستان",
  },
  {
    label: "لرستان",
    value: "لرستان",
  },
  {
    label: "گیلان",
    value: "گیلان",
  },
  {
    label: "مازندران",
    value: "مازندران",
  },
  {
    label: "مرکزی",
    value: "مرکزی",
  },
  {
    label: "هرمزگان",
    value: "هرمزگان",
  },
  {
    label: "همدان",
    value: "همدان",
  },
  {
    label: "یزد",
    value: "یزد",
  },
];

export default IranProvinces;
