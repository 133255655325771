import { SHA256 } from "crypto-js";
import Cookies from "js-cookie";

export default function useIVP() {
  /** Create an set a value for IPS in cookies */
  const setIVP = () => {
    let ips = SHA256(`${new Date().getTime()}`).toString().split("");
    ips[18] = "t";
    ips[12] = "r";
    ips[9] = "u";
    ips[15] = "e";
    Cookies.set("ivp", ips.join(""), { SameSite: "Lax", expires: 365 });
  };

  /** Check whether the current value of the IPS in cookies is valid or not */
  const checkIVP = () => {
    const ips = Cookies.get("ivp");

    if (ips) {
      return (
        ips[18] === "t" && ips[12] === "r" && ips[9] === "u" && ips[15] === "e"
      );
    }

    return false;
  };

  return {
    setIVP,
    checkIVP,
  };
}
