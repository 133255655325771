import { Link } from "react-router-dom";

import "./index.css";

const Ad = () => {
  return (
    <div className="header-add-container">
      <Link className="header-add" to="/f">
        هم اکنون به جمع خانواده با برکت شمیم بپیوندید
      </Link>
    </div>
  );
};

export default Ad;
