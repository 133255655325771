import { Link } from "react-router-dom";
import PwaContext from "../../utils/pwaContext";
import { useContext } from "react";
import { MdInstallMobile } from "react-icons/md";
import {
  RiPlayFill,
  RiArrowRightLine,
  RiInformationLine,
} from "react-icons/ri";
import {
  Flex,
  Heading,
  Box,
  IconButton,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  HStack,
  useDisclosure,
  useBreakpointValue,
  useToast,
  Skeleton,
  VStack,
  Text,
  Card,
} from "@chakra-ui/react";

export default function PageHeader({
  title = "",
  subtitle = "",
  drawerTitle = "",
  videoUrl = "",
  showBack = true,
  isLoading = false,
  styles = {},
  children,
  hasCard = false,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { canInstallApp, appinstalled, enabledPwa } = useContext(PwaContext);
  const toast = useToast();
  const drawerPlacement = useBreakpointValue(
    {
      base: "bottom",
      lg: "right",
    },
    { fallback: "base" }
  );
  return (
    <>
      <Flex
        w="100%"
        flexDirection="row"
        alignItems={showBack ? "flex-start" : "center"}
        justifyContent="space-between"
        mb="0.5rem"
        {...styles}
      >
        <VStack alignItems="start" spacing=".3rem">
          {showBack ? (
            <Button
              as={Link}
              to="../"
              variant="outline"
              gap="2"
              mb={title ? "1.2rem" : "0"}
            >
              <RiArrowRightLine />
              بازگشت
            </Button>
          ) : null}
        </VStack>

        <HStack>
          {!appinstalled && canInstallApp ? (
            <Button
              // className="glowing_rainbow_border"
              bg="var(--chakra-colors-chakra-body-bg)"
              variant="outline"
              leftIcon={<MdInstallMobile />}
              onClick={() => {
                onClose();

                if (!enabledPwa) {
                  toast({
                    title: "امکان نصب برنامه روی این مرورگر وجود ندارد!",
                    status: "error",
                    duration: 6000,
                  });
                } else {
                  const event = window.AppInstallEvent;
                  if (event) {
                    // Display install modal
                    event.prompt();
                    // event.userChoice.then((choiceResult) => {
                    //   if (choiceResult.outcome === "accepted") {}
                    // });
                  } else {
                    toast({
                      title: "امکان نصب برنامه روی این مرورگر وجود ندارد!",
                      status: "error",
                      duration: 6000,
                    });
                  }
                }
              }}
            >
              نصب‌اپ
            </Button>
          ) : null}

          {children ? (
            <IconButton
              aria-label="Guidance Button"
              onClick={() => onOpen()}
              variant="outline"
              icon={<RiInformationLine />}
              bg="var(--chakra-colors-chakra-body-bg)"
            />
          ) : (
            <Box />
          )}
        </HStack>
      </Flex>
      {(title || subtitle) && (
        <Card marginBottom={10} padding="10px" boxShadow={hasCard ? "base" : 0}>
          {isLoading ? (
            <Skeleton w="8.6rem" h="1.5rem" borderRadius="md" />
          ) : (
            <Heading as="h1" size="md">
              {title}
            </Heading>
          )}

          {subtitle ? (
            isLoading ? (
              <Skeleton w="6rem" h="1rem" borderRadius="md" />
            ) : (
              <Text opacity=".45">{subtitle}</Text>
            )
          ) : null}
        </Card>
      )}

      <Drawer
        isOpen={isOpen}
        placement={drawerPlacement}
        size="lg"
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{drawerTitle}</DrawerHeader>

          <DrawerBody>{children}</DrawerBody>

          <DrawerFooter
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap=".5rem"
          >
            {videoUrl.length > 0 ? (
              <Button
                as={Link}
                to={videoUrl}
                leftIcon={<RiPlayFill />}
                colorScheme="telegram"
              >
                نمایش ویدیو
              </Button>
            ) : null}

            <Button variant="outline" onClick={onClose}>
              بستن
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
