import {
  Button,
  Card,
  CardBody,
  CardProps,
  Heading,
  HStack,
  Icon,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { RiFileCopy2Line } from "react-icons/ri";
import { IoShareSocialOutline } from "react-icons/io5";

type ShareEventCardProps = {
  eventId?: string;
  isOwner?: boolean;
  publicationState?: string;
  styles?: CardProps;
};

export default function ShareEventCard(props: ShareEventCardProps) {
  const toast = useToast();

  if (
    props.eventId &&
    props.isOwner === true &&
    props.publicationState === "public"
  ) {
    return (
      <HStack justifyContent="center">
        <Button
          size="sm"
          colorScheme="telegram"
          variant={"outline"}
          onClick={() => {
            window.navigator
              .share({
                url: `https://shamim313.com/u/e/c/${props.eventId}`,
              })
              .catch((e) => {
                toast({
                  colorScheme: "orange",
                  title: "لینک اشتراک گذاری نشد!",
                  description:
                    "ممکن است به خاطر عدم پشتیبانی مرورگر شما از این امکان باشد.",
                });
              });
          }}
          margin="20px"
          marginTop={0}
        >
          <Icon as={IoShareSocialOutline} marginLeft={1} />
          لینک اشتراک گذاری پویش
        </Button>
      </HStack>
    );
  }

  return <></>;
}
