const IranCities: {
  [key: string]: {
    label: string;
    value: string;
  }[];
} = {
  "آذربایجان شرقی": [
    {
      label: "اسکو",
      value: "اسکو",
    },
    {
      label: "اهر",
      value: "اهر",
    },
    {
      label: "ایلخچی",
      value: "ایلخچی",
    },
    {
      label: "آبش احمد",
      value: "آبش احمد",
    },
    {
      label: "آذرشهر",
      value: "آذرشهر",
    },
    {
      label: "آقکند",
      value: "آقکند",
    },
    {
      label: "باسمنج",
      value: "باسمنج",
    },
    {
      label: "بخشایش",
      value: "بخشایش",
    },
    {
      label: "بستان آباد",
      value: "بستان آباد",
    },
    {
      label: "بناب",
      value: "بناب",
    },
    {
      label: "بناب جدید",
      value: "بناب جدید",
    },
    {
      label: "تبریز",
      value: "تبریز",
    },
    {
      label: "ترک",
      value: "ترک",
    },
    {
      label: "ترکمانچای",
      value: "ترکمانچای",
    },
    {
      label: "تسوج",
      value: "تسوج",
    },
    {
      label: "تیکمه داش",
      value: "تیکمه داش",
    },
    {
      label: "جلفا",
      value: "جلفا",
    },
    {
      label: "خاروانا",
      value: "خاروانا",
    },
    {
      label: "خامنه",
      value: "خامنه",
    },
    {
      label: "خراجو",
      value: "خراجو",
    },
    {
      label: "خسروشهر",
      value: "خسروشهر",
    },
    {
      label: "خضرلو",
      value: "خضرلو",
    },
    {
      label: "خمارلو",
      value: "خمارلو",
    },
    {
      label: "خواجه",
      value: "خواجه",
    },
    {
      label: "دوزدوزان",
      value: "دوزدوزان",
    },
    {
      label: "زرنق",
      value: "زرنق",
    },
    {
      label: "زنوز",
      value: "زنوز",
    },
    {
      label: "سراب",
      value: "سراب",
    },
    {
      label: "سردرود",
      value: "سردرود",
    },
    {
      label: "سهند",
      value: "سهند",
    },
    {
      label: "سیس",
      value: "سیس",
    },
    {
      label: "سیه رود",
      value: "سیه رود",
    },
    {
      label: "شبستر",
      value: "شبستر",
    },
    {
      label: "شربیان",
      value: "شربیان",
    },
    {
      label: "شرفخانه",
      value: "شرفخانه",
    },
    {
      label: "شندآباد",
      value: "شندآباد",
    },
    {
      label: "صوفیان",
      value: "صوفیان",
    },
    {
      label: "عجب شیر",
      value: "عجب شیر",
    },
    {
      label: "قره آغاج",
      value: "قره آغاج",
    },
    {
      label: "کشکسرای",
      value: "کشکسرای",
    },
    {
      label: "کلوانق",
      value: "کلوانق",
    },
    {
      label: "کلیبر",
      value: "کلیبر",
    },
    {
      label: "کوزه کنان",
      value: "کوزه کنان",
    },
    {
      label: "گوگان",
      value: "گوگان",
    },
    {
      label: "لیلان",
      value: "لیلان",
    },
    {
      label: "مراغه",
      value: "مراغه",
    },
    {
      label: "مرند",
      value: "مرند",
    },
    {
      label: "ملکان",
      value: "ملکان",
    },
    {
      label: "ملک کیان",
      value: "ملک کیان",
    },
    {
      label: "ممقان",
      value: "ممقان",
    },
    {
      label: "مهربان",
      value: "مهربان",
    },
    {
      label: "میانه",
      value: "میانه",
    },
    {
      label: "نظرکهریزی",
      value: "نظرکهریزی",
    },
    {
      label: "هادی شهر",
      value: "هادی شهر",
    },
    {
      label: "هرگلان",
      value: "هرگلان",
    },
    {
      label: "هریس",
      value: "هریس",
    },
    {
      label: "هشترود",
      value: "هشترود",
    },
    {
      label: "هوراند",
      value: "هوراند",
    },
    {
      label: "وایقان",
      value: "وایقان",
    },
    {
      label: "ورزقان",
      value: "ورزقان",
    },
    {
      label: "یامچی",
      value: "یامچی",
    },
  ],
  "آذربایجان غربی": [
    {
      label: "ارومیه",
      value: "ارومیه",
    },
    {
      label: "اشنویه",
      value: "اشنویه",
    },
    {
      label: "ایواوغلی",
      value: "ایواوغلی",
    },
    {
      label: "آواجیق",
      value: "آواجیق",
    },
    {
      label: "باروق",
      value: "باروق",
    },
    {
      label: "بازرگان",
      value: "بازرگان",
    },
    {
      label: "بوکان",
      value: "بوکان",
    },
    {
      label: "پلدشت",
      value: "پلدشت",
    },
    {
      label: "پیرانشهر",
      value: "پیرانشهر",
    },
    {
      label: "تازه شهر",
      value: "تازه شهر",
    },
    {
      label: "تکاب",
      value: "تکاب",
    },
    {
      label: "چهاربرج",
      value: "چهاربرج",
    },
    {
      label: "خوی",
      value: "خوی",
    },
    {
      label: "دیزج دیز",
      value: "دیزج دیز",
    },
    {
      label: "ربط",
      value: "ربط",
    },
    {
      label: "سردشت",
      value: "سردشت",
    },
    {
      label: "سرو",
      value: "سرو",
    },
    {
      label: "سلماس",
      value: "سلماس",
    },
    {
      label: "سیلوانه",
      value: "سیلوانه",
    },
    {
      label: "سیمینه",
      value: "سیمینه",
    },
    {
      label: "سیه چشمه",
      value: "سیه چشمه",
    },
    {
      label: "شاهین دژ",
      value: "شاهین دژ",
    },
    {
      label: "شوط",
      value: "شوط",
    },
    {
      label: "فیرورق",
      value: "فیرورق",
    },
    {
      label: "قره ضیاءالدین",
      value: "قره ضیاءالدین",
    },
    {
      label: "قطور",
      value: "قطور",
    },
    {
      label: "قوشچی",
      value: "قوشچی",
    },
    {
      label: "کشاورز",
      value: "کشاورز",
    },
    {
      label: "گردکشانه",
      value: "گردکشانه",
    },
    {
      label: "ماکو",
      value: "ماکو",
    },
    {
      label: "محمدیار",
      value: "محمدیار",
    },
    {
      label: "محمودآباد",
      value: "محمودآباد",
    },
    {
      label: "مهاباد",
      value: "مهاباد",
    },
    {
      label: "میاندوآب",
      value: "میاندوآب",
    },
    {
      label: "میرآباد",
      value: "میرآباد",
    },
    {
      label: "نالوس",
      value: "نالوس",
    },
    {
      label: "نقده",
      value: "نقده",
    },
    {
      label: "نوشین",
      value: "نوشین",
    },
  ],
  اردبیل: [
    {
      label: "اردبیل",
      value: "اردبیل",
    },
    {
      label: "اصلاندوز",
      value: "اصلاندوز",
    },
    {
      label: "آبی بیگلو",
      value: "آبی بیگلو",
    },
    {
      label: "بیله سوار",
      value: "بیله سوار",
    },
    {
      label: "پارس آباد",
      value: "پارس آباد",
    },
    {
      label: "تازه کند",
      value: "تازه کند",
    },
    {
      label: "تازه کندانگوت",
      value: "تازه کندانگوت",
    },
    {
      label: "جعفرآباد",
      value: "جعفرآباد",
    },
    {
      label: "خلخال",
      value: "خلخال",
    },
    {
      label: "رضی",
      value: "رضی",
    },
    {
      label: "سرعین",
      value: "سرعین",
    },
    {
      label: "عنبران",
      value: "عنبران",
    },
    {
      label: "فخرآباد",
      value: "فخرآباد",
    },
    {
      label: "کلور",
      value: "کلور",
    },
    {
      label: "کوراییم",
      value: "کوراییم",
    },
    {
      label: "گرمی",
      value: "گرمی",
    },
    {
      label: "گیوی",
      value: "گیوی",
    },
    {
      label: "لاهرود",
      value: "لاهرود",
    },
    {
      label: "مشگین شهر",
      value: "مشگین شهر",
    },
    {
      label: "نمین",
      value: "نمین",
    },
    {
      label: "نیر",
      value: "نیر",
    },
    {
      label: "هشتجین",
      value: "هشتجین",
    },
    {
      label: "هیر",
      value: "هیر",
    },
  ],
  اصفهان: [
    {
      label: "ابریشم",
      value: "ابریشم",
    },
    {
      label: "ابوزیدآباد",
      value: "ابوزیدآباد",
    },
    {
      label: "اردستان",
      value: "اردستان",
    },
    {
      label: "اژیه",
      value: "اژیه",
    },
    {
      label: "اصفهان",
      value: "اصفهان",
    },
    {
      label: "افوس",
      value: "افوس",
    },
    {
      label: "انارک",
      value: "انارک",
    },
    {
      label: "ایمانشهر",
      value: "ایمانشهر",
    },
    {
      label: "آران وبیدگل",
      value: "آران وبیدگل",
    },
    {
      label: "بادرود",
      value: "بادرود",
    },
    {
      label: "باغ بهادران",
      value: "باغ بهادران",
    },
    {
      label: "بافران",
      value: "بافران",
    },
    {
      label: "برزک",
      value: "برزک",
    },
    {
      label: "برف انبار",
      value: "برف انبار",
    },
    {
      label: "بهاران شهر",
      value: "بهاران شهر",
    },
    {
      label: "بهارستان",
      value: "بهارستان",
    },
    {
      label: "بوئین و میاندشت",
      value: "بوئین و میاندشت",
    },
    {
      label: "پیربکران",
      value: "پیربکران",
    },
    {
      label: "تودشک",
      value: "تودشک",
    },
    {
      label: "تیران",
      value: "تیران",
    },
    {
      label: "جندق",
      value: "جندق",
    },
    {
      label: "جوزدان",
      value: "جوزدان",
    },
    {
      label: "جوشقان و کامو",
      value: "جوشقان و کامو",
    },
    {
      label: "چادگان",
      value: "چادگان",
    },
    {
      label: "چرمهین",
      value: "چرمهین",
    },
    {
      label: "چمگردان",
      value: "چمگردان",
    },
    {
      label: "حبیب آباد",
      value: "حبیب آباد",
    },
    {
      label: "حسن آباد",
      value: "حسن آباد",
    },
    {
      label: "حنا",
      value: "حنا",
    },
    {
      label: "خالدآباد",
      value: "خالدآباد",
    },
    {
      label: "خمینی شهر",
      value: "خمینی شهر",
    },
    {
      label: "خوانسار",
      value: "خوانسار",
    },
    {
      label: "خور",
      value: "خور",
    },
    {
      label: "خورزوق",
      value: "خورزوق",
    },
    {
      label: "داران",
      value: "داران",
    },
    {
      label: "دامنه",
      value: "دامنه",
    },
    {
      label: "درچه",
      value: "درچه",
    },
    {
      label: "دستگرد",
      value: "دستگرد",
    },
    {
      label: "دهاقان",
      value: "دهاقان",
    },
    {
      label: "دهق",
      value: "دهق",
    },
    {
      label: "دولت آباد",
      value: "دولت آباد",
    },
    {
      label: "دیزیچه",
      value: "دیزیچه",
    },
    {
      label: "رزوه",
      value: "رزوه",
    },
    {
      label: "رضوانشهر",
      value: "رضوانشهر",
    },
    {
      label: "زاینده رود",
      value: "زاینده رود",
    },
    {
      label: "زرین شهر",
      value: "زرین شهر",
    },
    {
      label: "زواره",
      value: "زواره",
    },
    {
      label: "زیباشهر",
      value: "زیباشهر",
    },
    {
      label: "سده لنجان",
      value: "سده لنجان",
    },
    {
      label: "سفیدشهر",
      value: "سفیدشهر",
    },
    {
      label: "سگزی",
      value: "سگزی",
    },
    {
      label: "سمیرم",
      value: "سمیرم",
    },
    {
      label: "شاهین شهر",
      value: "شاهین شهر",
    },
    {
      label: "شهرضا",
      value: "شهرضا",
    },
    {
      label: "طالخونچه",
      value: "طالخونچه",
    },
    {
      label: "عسگران",
      value: "عسگران",
    },
    {
      label: "علویجه",
      value: "علویجه",
    },
    {
      label: "فرخی",
      value: "فرخی",
    },
    {
      label: "فریدونشهر",
      value: "فریدونشهر",
    },
    {
      label: "فلاورجان",
      value: "فلاورجان",
    },
    {
      label: "فولادشهر",
      value: "فولادشهر",
    },
    {
      label: "قمصر",
      value: "قمصر",
    },
    {
      label: "قهجاورستان",
      value: "قهجاورستان",
    },
    {
      label: "قهدریجان",
      value: "قهدریجان",
    },
    {
      label: "کاشان",
      value: "کاشان",
    },
    {
      label: "کرکوند",
      value: "کرکوند",
    },
    {
      label: "کلیشاد و سودرجان",
      value: "کلیشاد و سودرجان",
    },
    {
      label: "کمشچه",
      value: "کمشچه",
    },
    {
      label: "کمه",
      value: "کمه",
    },
    {
      label: "کهریزسنگ",
      value: "کهریزسنگ",
    },
    {
      label: "کوشک",
      value: "کوشک",
    },
    {
      label: "کوهپایه",
      value: "کوهپایه",
    },
    {
      label: "گرگاب",
      value: "گرگاب",
    },
    {
      label: "گزبرخوار",
      value: "گزبرخوار",
    },
    {
      label: "گلپایگان",
      value: "گلپایگان",
    },
    {
      label: "گلدشت",
      value: "گلدشت",
    },
    {
      label: "گلشهر",
      value: "گلشهر",
    },
    {
      label: "گوگد",
      value: "گوگد",
    },
    {
      label: "لای بید",
      value: "لای بید",
    },
    {
      label: "مبارکه",
      value: "مبارکه",
    },
    {
      label: "مجلسی",
      value: "مجلسی",
    },
    {
      label: "محمدآباد",
      value: "محمدآباد",
    },
    {
      label: "مشکات",
      value: "مشکات",
    },
    {
      label: "منظریه",
      value: "منظریه",
    },
    {
      label: "مهاباد",
      value: "مهاباد",
    },
    {
      label: "میمه",
      value: "میمه",
    },
    {
      label: "نائین",
      value: "نائین",
    },
    {
      label: "نجف آباد",
      value: "نجف آباد",
    },
    {
      label: "نصرآباد",
      value: "نصرآباد",
    },
    {
      label: "نطنز",
      value: "نطنز",
    },
    {
      label: "نوش آباد",
      value: "نوش آباد",
    },
    {
      label: "نیاسر",
      value: "نیاسر",
    },
    {
      label: "نیک آباد",
      value: "نیک آباد",
    },
    {
      label: "هرند",
      value: "هرند",
    },
    {
      label: "ورزنه",
      value: "ورزنه",
    },
    {
      label: "ورنامخواست",
      value: "ورنامخواست",
    },
    {
      label: "وزوان",
      value: "وزوان",
    },
    {
      label: "ونک",
      value: "ونک",
    },
  ],
  البرز: [
    {
      label: "اسارا",
      value: "اسارا",
    },
    {
      label: "اشتهارد",
      value: "اشتهارد",
    },
    {
      label: "تنکمان",
      value: "تنکمان",
    },
    {
      label: "چهارباغ",
      value: "چهارباغ",
    },
    {
      label: "سعید آباد",
      value: "سعید آباد",
    },
    {
      label: "شهر جدید هشتگرد",
      value: "شهر جدید هشتگرد",
    },
    {
      label: "طالقان",
      value: "طالقان",
    },
    {
      label: "کرج",
      value: "کرج",
    },
    {
      label: "کمال شهر",
      value: "کمال شهر",
    },
    {
      label: "کوهسار",
      value: "کوهسار",
    },
    {
      label: "گرمدره",
      value: "گرمدره",
    },
    {
      label: "ماهدشت",
      value: "ماهدشت",
    },
    {
      label: "محمدشهر",
      value: "محمدشهر",
    },
    {
      label: "مشکین دشت",
      value: "مشکین دشت",
    },
    {
      label: "نظرآباد",
      value: "نظرآباد",
    },
    {
      label: "هشتگرد",
      value: "هشتگرد",
    },
    {
      label: "فردیس",
      value: "فردیس",
    },
    {
      label: "مارلیک",
      value: "مارلیک",
    },
    {
      label: "کردان",
      value: "کردان",
    },
    {
      label: "ساوجبلاغ",
      value: "ساوجبلاغ",
    },
    {
      label: "تهران دشت",
      value: "تهران دشت",
    },
  ],
  ایلام: [
    {
      label: "ارکواز",
      value: "ارکواز",
    },
    {
      label: "ایلام",
      value: "ایلام",
    },
    {
      label: "ایوان",
      value: "ایوان",
    },
    {
      label: "آبدانان",
      value: "آبدانان",
    },
    {
      label: "آسمان آباد",
      value: "آسمان آباد",
    },
    {
      label: "بدره",
      value: "بدره",
    },
    {
      label: "پهله",
      value: "پهله",
    },
    {
      label: "توحید",
      value: "توحید",
    },
    {
      label: "چوار",
      value: "چوار",
    },
    {
      label: "دره شهر",
      value: "دره شهر",
    },
    {
      label: "دلگشا",
      value: "دلگشا",
    },
    {
      label: "دهلران",
      value: "دهلران",
    },
    {
      label: "زرنه",
      value: "زرنه",
    },
    {
      label: "سراب باغ",
      value: "سراب باغ",
    },
    {
      label: "سرابله",
      value: "سرابله",
    },
    {
      label: "صالح آباد",
      value: "صالح آباد",
    },
    {
      label: "لومار",
      value: "لومار",
    },
    {
      label: "مهران",
      value: "مهران",
    },
    {
      label: "مورموری",
      value: "مورموری",
    },
    {
      label: "موسیان",
      value: "موسیان",
    },
    {
      label: "میمه",
      value: "میمه",
    },
  ],
  بوشهر: [
    {
      label: "امام حسن",
      value: "امام حسن",
    },
    {
      label: "انارستان",
      value: "انارستان",
    },
    {
      label: "اهرم",
      value: "اهرم",
    },
    {
      label: "آب پخش",
      value: "آب پخش",
    },
    {
      label: "آبدان",
      value: "آبدان",
    },
    {
      label: "برازجان",
      value: "برازجان",
    },
    {
      label: "بردخون",
      value: "بردخون",
    },
    {
      label: "بندردیر",
      value: "بندردیر",
    },
    {
      label: "بندردیلم",
      value: "بندردیلم",
    },
    {
      label: "بندرریگ",
      value: "بندرریگ",
    },
    {
      label: "بندرکنگان",
      value: "بندرکنگان",
    },
    {
      label: "بندرگناوه",
      value: "بندرگناوه",
    },
    {
      label: "بنک",
      value: "بنک",
    },
    {
      label: "بوشهر",
      value: "بوشهر",
    },
    {
      label: "تنگ ارم",
      value: "تنگ ارم",
    },
    {
      label: "جم",
      value: "جم",
    },
    {
      label: "چغادک",
      value: "چغادک",
    },
    {
      label: "خارک",
      value: "خارک",
    },
    {
      label: "خورموج",
      value: "خورموج",
    },
    {
      label: "دالکی",
      value: "دالکی",
    },
    {
      label: "دلوار",
      value: "دلوار",
    },
    {
      label: "ریز",
      value: "ریز",
    },
    {
      label: "سعدآباد",
      value: "سعدآباد",
    },
    {
      label: "سیراف",
      value: "سیراف",
    },
    {
      label: "شبانکاره",
      value: "شبانکاره",
    },
    {
      label: "شنبه",
      value: "شنبه",
    },
    {
      label: "عسلویه",
      value: "عسلویه",
    },
    {
      label: "کاکی",
      value: "کاکی",
    },
    {
      label: "کلمه",
      value: "کلمه",
    },
    {
      label: "نخل تقی",
      value: "نخل تقی",
    },
    {
      label: "وحدتیه",
      value: "وحدتیه",
    },
  ],
  تهران: [
    {
      label: "ارجمند",
      value: "ارجمند",
    },
    {
      label: "اسلامشهر",
      value: "اسلامشهر",
    },
    {
      label: "اندیشه",
      value: "اندیشه",
    },
    {
      label: "آبسرد",
      value: "آبسرد",
    },
    {
      label: "آبعلی",
      value: "آبعلی",
    },
    {
      label: "باغستان",
      value: "باغستان",
    },
    {
      label: "باقرشهر",
      value: "باقرشهر",
    },
    {
      label: "بومهن",
      value: "بومهن",
    },
    {
      label: "پاکدشت",
      value: "پاکدشت",
    },
    {
      label: "پردیس",
      value: "پردیس",
    },
    {
      label: "پیشوا",
      value: "پیشوا",
    },
    {
      label: "تهران",
      value: "تهران",
    },
    {
      label: "جوادآباد",
      value: "جوادآباد",
    },
    {
      label: "چهاردانگه",
      value: "چهاردانگه",
    },
    {
      label: "حسن آباد",
      value: "حسن آباد",
    },
    {
      label: "دماوند",
      value: "دماوند",
    },
    {
      label: "دیزین",
      value: "دیزین",
    },
    {
      label: "شهر ری",
      value: "شهر ری",
    },
    {
      label: "رباط کریم",
      value: "رباط کریم",
    },
    {
      label: "رودهن",
      value: "رودهن",
    },
    {
      label: "شاهدشهر",
      value: "شاهدشهر",
    },
    {
      label: "شریف آباد",
      value: "شریف آباد",
    },
    {
      label: "شمشک",
      value: "شمشک",
    },
    {
      label: "شهریار",
      value: "شهریار",
    },
    {
      label: "صالح آباد",
      value: "صالح آباد",
    },
    {
      label: "صباشهر",
      value: "صباشهر",
    },
    {
      label: "صفادشت",
      value: "صفادشت",
    },
    {
      label: "فردوسیه",
      value: "فردوسیه",
    },
    {
      label: "فشم",
      value: "فشم",
    },
    {
      label: "فیروزکوه",
      value: "فیروزکوه",
    },
    {
      label: "قدس",
      value: "قدس",
    },
    {
      label: "قرچک",
      value: "قرچک",
    },
    {
      label: "کهریزک",
      value: "کهریزک",
    },
    {
      label: "کیلان",
      value: "کیلان",
    },
    {
      label: "گلستان",
      value: "گلستان",
    },
    {
      label: "لواسان",
      value: "لواسان",
    },
    {
      label: "ملارد",
      value: "ملارد",
    },
    {
      label: "میگون",
      value: "میگون",
    },
    {
      label: "نسیم شهر",
      value: "نسیم شهر",
    },
    {
      label: "نصیرآباد",
      value: "نصیرآباد",
    },
    {
      label: "وحیدیه",
      value: "وحیدیه",
    },
    {
      label: "ورامین",
      value: "ورامین",
    },
    {
      label: "پرند",
      value: "پرند",
    },
    {
      label: "قیامدشت",
      value: "قیامدشت",
    },
  ],
  "چهارمحال و بختیاری": [
    {
      label: "اردل",
      value: "اردل",
    },
    {
      label: "آلونی",
      value: "آلونی",
    },
    {
      label: "باباحیدر",
      value: "باباحیدر",
    },
    {
      label: "بروجن",
      value: "بروجن",
    },
    {
      label: "بلداجی",
      value: "بلداجی",
    },
    {
      label: "بن",
      value: "بن",
    },
    {
      label: "جونقان",
      value: "جونقان",
    },
    {
      label: "چلگرد",
      value: "چلگرد",
    },
    {
      label: "سامان",
      value: "سامان",
    },
    {
      label: "سفیددشت",
      value: "سفیددشت",
    },
    {
      label: "سودجان",
      value: "سودجان",
    },
    {
      label: "سورشجان",
      value: "سورشجان",
    },
    {
      label: "شلمزار",
      value: "شلمزار",
    },
    {
      label: "شهرکرد",
      value: "شهرکرد",
    },
    {
      label: "طاقانک",
      value: "طاقانک",
    },
    {
      label: "فارسان",
      value: "فارسان",
    },
    {
      label: "فرادنبه",
      value: "فرادنبه",
    },
    {
      label: "فرخ شهر",
      value: "فرخ شهر",
    },
    {
      label: "کیان",
      value: "کیان",
    },
    {
      label: "گندمان",
      value: "گندمان",
    },
    {
      label: "گهرو",
      value: "گهرو",
    },
    {
      label: "لردگان",
      value: "لردگان",
    },
    {
      label: "مال خلیفه",
      value: "مال خلیفه",
    },
    {
      label: "ناغان",
      value: "ناغان",
    },
    {
      label: "نافچ",
      value: "نافچ",
    },
    {
      label: "نقنه",
      value: "نقنه",
    },
    {
      label: "هفشجان",
      value: "هفشجان",
    },
  ],
  "خراسان جنوبی": [
    {
      label: "ارسک",
      value: "ارسک",
    },
    {
      label: "اسدیه",
      value: "اسدیه",
    },
    {
      label: "اسفدن",
      value: "اسفدن",
    },
    {
      label: "اسلامیه",
      value: "اسلامیه",
    },
    {
      label: "آرین شهر",
      value: "آرین شهر",
    },
    {
      label: "آیسک",
      value: "آیسک",
    },
    {
      label: "بشرویه",
      value: "بشرویه",
    },
    {
      label: "بیرجند",
      value: "بیرجند",
    },
    {
      label: "حاجی آباد",
      value: "حاجی آباد",
    },
    {
      label: "خضری دشت بیاض",
      value: "خضری دشت بیاض",
    },
    {
      label: "خوسف",
      value: "خوسف",
    },
    {
      label: "زهان",
      value: "زهان",
    },
    {
      label: "سرایان",
      value: "سرایان",
    },
    {
      label: "سربیشه",
      value: "سربیشه",
    },
    {
      label: "سه قلعه",
      value: "سه قلعه",
    },
    {
      label: "شوسف",
      value: "شوسف",
    },
    {
      label: "طبس ",
      value: "طبس ",
    },
    {
      label: "فردوس",
      value: "فردوس",
    },
    {
      label: "قاین",
      value: "قاین",
    },
    {
      label: "قهستان",
      value: "قهستان",
    },
    {
      label: "محمدشهر",
      value: "محمدشهر",
    },
    {
      label: "مود",
      value: "مود",
    },
    {
      label: "نهبندان",
      value: "نهبندان",
    },
    {
      label: "نیمبلوک",
      value: "نیمبلوک",
    },
  ],
  "خراسان رضوی": [
    {
      label: "احمدآباد صولت",
      value: "احمدآباد صولت",
    },
    {
      label: "انابد",
      value: "انابد",
    },
    {
      label: "باجگیران",
      value: "باجگیران",
    },
    {
      label: "باخرز",
      value: "باخرز",
    },
    {
      label: "بار",
      value: "بار",
    },
    {
      label: "بایگ",
      value: "بایگ",
    },
    {
      label: "بجستان",
      value: "بجستان",
    },
    {
      label: "بردسکن",
      value: "بردسکن",
    },
    {
      label: "بیدخت",
      value: "بیدخت",
    },
    {
      label: "تایباد",
      value: "تایباد",
    },
    {
      label: "تربت جام",
      value: "تربت جام",
    },
    {
      label: "تربت حیدریه",
      value: "تربت حیدریه",
    },
    {
      label: "جغتای",
      value: "جغتای",
    },
    {
      label: "جنگل",
      value: "جنگل",
    },
    {
      label: "چاپشلو",
      value: "چاپشلو",
    },
    {
      label: "چکنه",
      value: "چکنه",
    },
    {
      label: "چناران",
      value: "چناران",
    },
    {
      label: "خرو",
      value: "خرو",
    },
    {
      label: "خلیل آباد",
      value: "خلیل آباد",
    },
    {
      label: "خواف",
      value: "خواف",
    },
    {
      label: "داورزن",
      value: "داورزن",
    },
    {
      label: "درگز",
      value: "درگز",
    },
    {
      label: "در رود",
      value: "در رود",
    },
    {
      label: "دولت آباد",
      value: "دولت آباد",
    },
    {
      label: "رباط سنگ",
      value: "رباط سنگ",
    },
    {
      label: "رشتخوار",
      value: "رشتخوار",
    },
    {
      label: "رضویه",
      value: "رضویه",
    },
    {
      label: "روداب",
      value: "روداب",
    },
    {
      label: "ریوش",
      value: "ریوش",
    },
    {
      label: "سبزوار",
      value: "سبزوار",
    },
    {
      label: "سرخس",
      value: "سرخس",
    },
    {
      label: "سفیدسنگ",
      value: "سفیدسنگ",
    },
    {
      label: "سلامی",
      value: "سلامی",
    },
    {
      label: "سلطان آباد",
      value: "سلطان آباد",
    },
    {
      label: "سنگان",
      value: "سنگان",
    },
    {
      label: "شادمهر",
      value: "شادمهر",
    },
    {
      label: "شاندیز",
      value: "شاندیز",
    },
    {
      label: "ششتمد",
      value: "ششتمد",
    },
    {
      label: "شهرآباد",
      value: "شهرآباد",
    },
    {
      label: "شهرزو",
      value: "شهرزو",
    },
    {
      label: "صالح آباد",
      value: "صالح آباد",
    },
    {
      label: "طرقبه",
      value: "طرقبه",
    },
    {
      label: "عشق آباد",
      value: "عشق آباد",
    },
    {
      label: "فرهادگرد",
      value: "فرهادگرد",
    },
    {
      label: "فریمان",
      value: "فریمان",
    },
    {
      label: "فیروزه",
      value: "فیروزه",
    },
    {
      label: "فیض آباد",
      value: "فیض آباد",
    },
    {
      label: "قاسم آباد",
      value: "قاسم آباد",
    },
    {
      label: "قدمگاه",
      value: "قدمگاه",
    },
    {
      label: "قلندرآباد",
      value: "قلندرآباد",
    },
    {
      label: "قوچان",
      value: "قوچان",
    },
    {
      label: "کاخک",
      value: "کاخک",
    },
    {
      label: "کاریز",
      value: "کاریز",
    },
    {
      label: "کاشمر",
      value: "کاشمر",
    },
    {
      label: "کدکن",
      value: "کدکن",
    },
    {
      label: "کلات",
      value: "کلات",
    },
    {
      label: "کندر",
      value: "کندر",
    },
    {
      label: "گلمکان",
      value: "گلمکان",
    },
    {
      label: "گناباد",
      value: "گناباد",
    },
    {
      label: "لطف آباد",
      value: "لطف آباد",
    },
    {
      label: "مزدآوند",
      value: "مزدآوند",
    },
    {
      label: "مشهد",
      value: "مشهد",
    },
    {
      label: "ملک آباد",
      value: "ملک آباد",
    },
    {
      label: "نشتیفان",
      value: "نشتیفان",
    },
    {
      label: "نصرآباد",
      value: "نصرآباد",
    },
    {
      label: "نقاب",
      value: "نقاب",
    },
    {
      label: "نوخندان",
      value: "نوخندان",
    },
    {
      label: "نیشابور",
      value: "نیشابور",
    },
    {
      label: "نیل شهر",
      value: "نیل شهر",
    },
    {
      label: "همت آباد",
      value: "همت آباد",
    },
    {
      label: "یونسی",
      value: "یونسی",
    },
    {
      label: "گلبهار",
      value: "گلبهار",
    },
    {
      label: "بینالود",
      value: "بینالود",
    },
  ],
  "خراسان شمالی": [
    {
      label: "اسفراین",
      value: "اسفراین",
    },
    {
      label: "ایور",
      value: "ایور",
    },
    {
      label: "آشخانه",
      value: "آشخانه",
    },
    {
      label: "بجنورد",
      value: "بجنورد",
    },
    {
      label: "پیش قلعه",
      value: "پیش قلعه",
    },
    {
      label: "تیتکانلو",
      value: "تیتکانلو",
    },
    {
      label: "جاجرم",
      value: "جاجرم",
    },
    {
      label: "حصارگرمخان",
      value: "حصارگرمخان",
    },
    {
      label: "درق",
      value: "درق",
    },
    {
      label: "راز",
      value: "راز",
    },
    {
      label: "سنخواست",
      value: "سنخواست",
    },
    {
      label: "شوقان",
      value: "شوقان",
    },
    {
      label: "شیروان",
      value: "شیروان",
    },
    {
      label: "صفی آباد",
      value: "صفی آباد",
    },
    {
      label: "فاروج",
      value: "فاروج",
    },
    {
      label: "قاضی",
      value: "قاضی",
    },
    {
      label: "گرمه",
      value: "گرمه",
    },
    {
      label: "لوجلی",
      value: "لوجلی",
    },
  ],
  خوزستان: [
    {
      label: "اروندکنار",
      value: "اروندکنار",
    },
    {
      label: "الوان",
      value: "الوان",
    },
    {
      label: "امیدیه",
      value: "امیدیه",
    },
    {
      label: "اندیمشک",
      value: "اندیمشک",
    },
    {
      label: "اهواز",
      value: "اهواز",
    },
    {
      label: "ایذه",
      value: "ایذه",
    },
    {
      label: "آبادان",
      value: "آبادان",
    },
    {
      label: "آغاجاری",
      value: "آغاجاری",
    },
    {
      label: "باغ ملک",
      value: "باغ ملک",
    },
    {
      label: "بستان",
      value: "بستان",
    },
    {
      label: "بندرامام خمینی",
      value: "بندرامام خمینی",
    },
    {
      label: "بندرماهشهر",
      value: "بندرماهشهر",
    },
    {
      label: "بهبهان",
      value: "بهبهان",
    },
    {
      label: "ترکالکی",
      value: "ترکالکی",
    },
    {
      label: "جایزان",
      value: "جایزان",
    },
    {
      label: "چمران",
      value: "چمران",
    },
    {
      label: "چویبده",
      value: "چویبده",
    },
    {
      label: "حر",
      value: "حر",
    },
    {
      label: "حسینیه",
      value: "حسینیه",
    },
    {
      label: "حمزه",
      value: "حمزه",
    },
    {
      label: "حمیدیه",
      value: "حمیدیه",
    },
    {
      label: "خرمشهر",
      value: "خرمشهر",
    },
    {
      label: "دارخوین",
      value: "دارخوین",
    },
    {
      label: "دزآب",
      value: "دزآب",
    },
    {
      label: "دزفول",
      value: "دزفول",
    },
    {
      label: "دهدز",
      value: "دهدز",
    },
    {
      label: "رامشیر",
      value: "رامشیر",
    },
    {
      label: "رامهرمز",
      value: "رامهرمز",
    },
    {
      label: "رفیع",
      value: "رفیع",
    },
    {
      label: "زهره",
      value: "زهره",
    },
    {
      label: "سالند",
      value: "سالند",
    },
    {
      label: "سردشت",
      value: "سردشت",
    },
    {
      label: "سوسنگرد",
      value: "سوسنگرد",
    },
    {
      label: "شادگان",
      value: "شادگان",
    },
    {
      label: "شاوور",
      value: "شاوور",
    },
    {
      label: "شرافت",
      value: "شرافت",
    },
    {
      label: "شوش",
      value: "شوش",
    },
    {
      label: "شوشتر",
      value: "شوشتر",
    },
    {
      label: "شیبان",
      value: "شیبان",
    },
    {
      label: "صالح شهر",
      value: "صالح شهر",
    },
    {
      label: "صفی آباد",
      value: "صفی آباد",
    },
    {
      label: "صیدون",
      value: "صیدون",
    },
    {
      label: "قلعه تل",
      value: "قلعه تل",
    },
    {
      label: "قلعه خواجه",
      value: "قلعه خواجه",
    },
    {
      label: "گتوند",
      value: "گتوند",
    },
    {
      label: "لالی",
      value: "لالی",
    },
    {
      label: "مسجدسلیمان",
      value: "مسجدسلیمان",
    },
    {
      label: "ملاثانی",
      value: "ملاثانی",
    },
    {
      label: "میانرود",
      value: "میانرود",
    },
    {
      label: "مینوشهر",
      value: "مینوشهر",
    },
    {
      label: "هفتگل",
      value: "هفتگل",
    },
    {
      label: "هندیجان",
      value: "هندیجان",
    },
    {
      label: "هویزه",
      value: "هویزه",
    },
    {
      label: "ویس",
      value: "ویس",
    },
  ],
  زنجان: [
    {
      label: "ابهر",
      value: "ابهر",
    },
    {
      label: "ارمغان خانه",
      value: "ارمغان خانه",
    },
    {
      label: "آب بر",
      value: "آب بر",
    },
    {
      label: "چورزق",
      value: "چورزق",
    },
    {
      label: "حلب",
      value: "حلب",
    },
    {
      label: "خرمدره",
      value: "خرمدره",
    },
    {
      label: "دندی",
      value: "دندی",
    },
    {
      label: "زرین آباد",
      value: "زرین آباد",
    },
    {
      label: "زرین رود",
      value: "زرین رود",
    },
    {
      label: "زنجان",
      value: "زنجان",
    },
    {
      label: "سجاس",
      value: "سجاس",
    },
    {
      label: "سلطانیه",
      value: "سلطانیه",
    },
    {
      label: "سهرورد",
      value: "سهرورد",
    },
    {
      label: "صائین قلعه",
      value: "صائین قلعه",
    },
    {
      label: "قیدار",
      value: "قیدار",
    },
    {
      label: "گرماب",
      value: "گرماب",
    },
    {
      label: "ماه نشان",
      value: "ماه نشان",
    },
    {
      label: "هیدج",
      value: "هیدج",
    },
  ],
  سمنان: [
    {
      label: "امیریه",
      value: "امیریه",
    },
    {
      label: "ایوانکی",
      value: "ایوانکی",
    },
    {
      label: "آرادان",
      value: "آرادان",
    },
    {
      label: "بسطام",
      value: "بسطام",
    },
    {
      label: "بیارجمند",
      value: "بیارجمند",
    },
    {
      label: "دامغان",
      value: "دامغان",
    },
    {
      label: "درجزین",
      value: "درجزین",
    },
    {
      label: "دیباج",
      value: "دیباج",
    },
    {
      label: "سرخه",
      value: "سرخه",
    },
    {
      label: "سمنان",
      value: "سمنان",
    },
    {
      label: "شاهرود",
      value: "شاهرود",
    },
    {
      label: "شهمیرزاد",
      value: "شهمیرزاد",
    },
    {
      label: "کلاته خیج",
      value: "کلاته خیج",
    },
    {
      label: "گرمسار",
      value: "گرمسار",
    },
    {
      label: "مجن",
      value: "مجن",
    },
    {
      label: "مهدی شهر",
      value: "مهدی شهر",
    },
    {
      label: "میامی",
      value: "میامی",
    },
  ],
  "سیستان و بلوچستان": [
    {
      label: "ادیمی",
      value: "ادیمی",
    },
    {
      label: "اسپکه",
      value: "اسپکه",
    },
    {
      label: "ایرانشهر",
      value: "ایرانشهر",
    },
    {
      label: "بزمان",
      value: "بزمان",
    },
    {
      label: "بمپور",
      value: "بمپور",
    },
    {
      label: "بنت",
      value: "بنت",
    },
    {
      label: "بنجار",
      value: "بنجار",
    },
    {
      label: "پیشین",
      value: "پیشین",
    },
    {
      label: "جالق",
      value: "جالق",
    },
    {
      label: "چابهار",
      value: "چابهار",
    },
    {
      label: "خاش",
      value: "خاش",
    },
    {
      label: "دوست محمد",
      value: "دوست محمد",
    },
    {
      label: "راسک",
      value: "راسک",
    },
    {
      label: "زابل",
      value: "زابل",
    },
    {
      label: "زابلی",
      value: "زابلی",
    },
    {
      label: "زاهدان",
      value: "زاهدان",
    },
    {
      label: "زهک",
      value: "زهک",
    },
    {
      label: "سراوان",
      value: "سراوان",
    },
    {
      label: "سرباز",
      value: "سرباز",
    },
    {
      label: "سوران",
      value: "سوران",
    },
    {
      label: "سیرکان",
      value: "سیرکان",
    },
    {
      label: "علی اکبر",
      value: "علی اکبر",
    },
    {
      label: "فنوج",
      value: "فنوج",
    },
    {
      label: "قصرقند",
      value: "قصرقند",
    },
    {
      label: "کنارک",
      value: "کنارک",
    },
    {
      label: "گشت",
      value: "گشت",
    },
    {
      label: "گلمورتی",
      value: "گلمورتی",
    },
    {
      label: "محمدان",
      value: "محمدان",
    },
    {
      label: "محمدآباد",
      value: "محمدآباد",
    },
    {
      label: "محمدی",
      value: "محمدی",
    },
    {
      label: "میرجاوه",
      value: "میرجاوه",
    },
    {
      label: "نصرت آباد",
      value: "نصرت آباد",
    },
    {
      label: "نگور",
      value: "نگور",
    },
    {
      label: "نوک آباد",
      value: "نوک آباد",
    },
    {
      label: "نیک شهر",
      value: "نیک شهر",
    },
    {
      label: "هیدوچ",
      value: "هیدوچ",
    },
  ],
  فارس: [
    {
      label: "اردکان",
      value: "اردکان",
    },
    {
      label: "ارسنجان",
      value: "ارسنجان",
    },
    {
      label: "استهبان",
      value: "استهبان",
    },
    {
      label: "اشکنان",
      value: "اشکنان",
    },
    {
      label: "افزر",
      value: "افزر",
    },
    {
      label: "اقلید",
      value: "اقلید",
    },
    {
      label: "امام شهر",
      value: "امام شهر",
    },
    {
      label: "اهل",
      value: "اهل",
    },
    {
      label: "اوز",
      value: "اوز",
    },
    {
      label: "ایج",
      value: "ایج",
    },
    {
      label: "ایزدخواست",
      value: "ایزدخواست",
    },
    {
      label: "آباده",
      value: "آباده",
    },
    {
      label: "آباده طشک",
      value: "آباده طشک",
    },
    {
      label: "باب انار",
      value: "باب انار",
    },
    {
      label: "بالاده",
      value: "بالاده",
    },
    {
      label: "بنارویه",
      value: "بنارویه",
    },
    {
      label: "بهمن",
      value: "بهمن",
    },
    {
      label: "بوانات",
      value: "بوانات",
    },
    {
      label: "بیرم",
      value: "بیرم",
    },
    {
      label: "بیضا",
      value: "بیضا",
    },
    {
      label: "جنت شهر",
      value: "جنت شهر",
    },
    {
      label: "جهرم",
      value: "جهرم",
    },
    {
      label: "جویم",
      value: "جویم",
    },
    {
      label: "زرین دشت",
      value: "زرین دشت",
    },
    {
      label: "حسن آباد",
      value: "حسن آباد",
    },
    {
      label: "خان زنیان",
      value: "خان زنیان",
    },
    {
      label: "خاوران",
      value: "خاوران",
    },
    {
      label: "خرامه",
      value: "خرامه",
    },
    {
      label: "خشت",
      value: "خشت",
    },
    {
      label: "خنج",
      value: "خنج",
    },
    {
      label: "خور",
      value: "خور",
    },
    {
      label: "داراب",
      value: "داراب",
    },
    {
      label: "داریان",
      value: "داریان",
    },
    {
      label: "دبیران",
      value: "دبیران",
    },
    {
      label: "دژکرد",
      value: "دژکرد",
    },
    {
      label: "دهرم",
      value: "دهرم",
    },
    {
      label: "دوبرجی",
      value: "دوبرجی",
    },
    {
      label: "رامجرد",
      value: "رامجرد",
    },
    {
      label: "رونیز",
      value: "رونیز",
    },
    {
      label: "زاهدشهر",
      value: "زاهدشهر",
    },
    {
      label: "زرقان",
      value: "زرقان",
    },
    {
      label: "سده",
      value: "سده",
    },
    {
      label: "سروستان",
      value: "سروستان",
    },
    {
      label: "سعادت شهر",
      value: "سعادت شهر",
    },
    {
      label: "سورمق",
      value: "سورمق",
    },
    {
      label: "سیدان",
      value: "سیدان",
    },
    {
      label: "ششده",
      value: "ششده",
    },
    {
      label: "شهرپیر",
      value: "شهرپیر",
    },
    {
      label: "شهرصدرا",
      value: "شهرصدرا",
    },
    {
      label: "شیراز",
      value: "شیراز",
    },
    {
      label: "صغاد",
      value: "صغاد",
    },
    {
      label: "صفاشهر",
      value: "صفاشهر",
    },
    {
      label: "علامرودشت",
      value: "علامرودشت",
    },
    {
      label: "فدامی",
      value: "فدامی",
    },
    {
      label: "فراشبند",
      value: "فراشبند",
    },
    {
      label: "فسا",
      value: "فسا",
    },
    {
      label: "فیروزآباد",
      value: "فیروزآباد",
    },
    {
      label: "قائمیه",
      value: "قائمیه",
    },
    {
      label: "قادرآباد",
      value: "قادرآباد",
    },
    {
      label: "قطب آباد",
      value: "قطب آباد",
    },
    {
      label: "قطرویه",
      value: "قطرویه",
    },
    {
      label: "قیر",
      value: "قیر",
    },
    {
      label: "کارزین (فتح آباد)",
      value: "کارزین (فتح آباد)",
    },
    {
      label: "کازرون",
      value: "کازرون",
    },
    {
      label: "کامفیروز",
      value: "کامفیروز",
    },
    {
      label: "کره ای",
      value: "کره ای",
    },
    {
      label: "کنارتخته",
      value: "کنارتخته",
    },
    {
      label: "کوار",
      value: "کوار",
    },
    {
      label: "گراش",
      value: "گراش",
    },
    {
      label: "گله دار",
      value: "گله دار",
    },
    {
      label: "لار",
      value: "لار",
    },
    {
      label: "لامرد",
      value: "لامرد",
    },
    {
      label: "لپویی",
      value: "لپویی",
    },
    {
      label: "لطیفی",
      value: "لطیفی",
    },
    {
      label: "مبارک آباددیز",
      value: "مبارک آباددیز",
    },
    {
      label: "مرودشت",
      value: "مرودشت",
    },
    {
      label: "مشکان",
      value: "مشکان",
    },
    {
      label: "مصیری",
      value: "مصیری",
    },
    {
      label: "مهر",
      value: "مهر",
    },
    {
      label: "میمند",
      value: "میمند",
    },
    {
      label: "نوبندگان",
      value: "نوبندگان",
    },
    {
      label: "نوجین",
      value: "نوجین",
    },
    {
      label: "نودان",
      value: "نودان",
    },
    {
      label: "نورآباد",
      value: "نورآباد",
    },
    {
      label: "نی ریز",
      value: "نی ریز",
    },
    {
      label: "وراوی",
      value: "وراوی",
    },
  ],
  قزوین: [
    {
      label: "ارداق",
      value: "ارداق",
    },
    {
      label: "اسفرورین",
      value: "اسفرورین",
    },
    {
      label: "اقبالیه",
      value: "اقبالیه",
    },
    {
      label: "الوند",
      value: "الوند",
    },
    {
      label: "آبگرم",
      value: "آبگرم",
    },
    {
      label: "آبیک",
      value: "آبیک",
    },
    {
      label: "آوج",
      value: "آوج",
    },
    {
      label: "بوئین زهرا",
      value: "بوئین زهرا",
    },
    {
      label: "بیدستان",
      value: "بیدستان",
    },
    {
      label: "تاکستان",
      value: "تاکستان",
    },
    {
      label: "خاکعلی",
      value: "خاکعلی",
    },
    {
      label: "خرمدشت",
      value: "خرمدشت",
    },
    {
      label: "دانسفهان",
      value: "دانسفهان",
    },
    {
      label: "رازمیان",
      value: "رازمیان",
    },
    {
      label: "سگزآباد",
      value: "سگزآباد",
    },
    {
      label: "سیردان",
      value: "سیردان",
    },
    {
      label: "شال",
      value: "شال",
    },
    {
      label: "شریفیه",
      value: "شریفیه",
    },
    {
      label: "ضیاآباد",
      value: "ضیاآباد",
    },
    {
      label: "قزوین",
      value: "قزوین",
    },
    {
      label: "کوهین",
      value: "کوهین",
    },
    {
      label: "محمدیه",
      value: "محمدیه",
    },
    {
      label: "محمودآباد نمونه",
      value: "محمودآباد نمونه",
    },
    {
      label: "معلم کلایه",
      value: "معلم کلایه",
    },
    {
      label: "نرجه",
      value: "نرجه",
    },
  ],
  قم: [
    {
      label: "جعفریه",
      value: "جعفریه",
    },
    {
      label: "دستجرد",
      value: "دستجرد",
    },
    {
      label: "سلفچگان",
      value: "سلفچگان",
    },
    {
      label: "قم",
      value: "قم",
    },
    {
      label: "قنوات",
      value: "قنوات",
    },
    {
      label: "کهک",
      value: "کهک",
    },
  ],
  کردستان: [
    {
      label: "آرمرده",
      value: "آرمرده",
    },
    {
      label: "بابارشانی",
      value: "بابارشانی",
    },
    {
      label: "بانه",
      value: "بانه",
    },
    {
      label: "بلبان آباد",
      value: "بلبان آباد",
    },
    {
      label: "بوئین سفلی",
      value: "بوئین سفلی",
    },
    {
      label: "بیجار",
      value: "بیجار",
    },
    {
      label: "چناره",
      value: "چناره",
    },
    {
      label: "دزج",
      value: "دزج",
    },
    {
      label: "دلبران",
      value: "دلبران",
    },
    {
      label: "دهگلان",
      value: "دهگلان",
    },
    {
      label: "دیواندره",
      value: "دیواندره",
    },
    {
      label: "زرینه",
      value: "زرینه",
    },
    {
      label: "سروآباد",
      value: "سروآباد",
    },
    {
      label: "سریش آباد",
      value: "سریش آباد",
    },
    {
      label: "سقز",
      value: "سقز",
    },
    {
      label: "سنندج",
      value: "سنندج",
    },
    {
      label: "شویشه",
      value: "شویشه",
    },
    {
      label: "صاحب",
      value: "صاحب",
    },
    {
      label: "قروه",
      value: "قروه",
    },
    {
      label: "کامیاران",
      value: "کامیاران",
    },
    {
      label: "کانی دینار",
      value: "کانی دینار",
    },
    {
      label: "کانی سور",
      value: "کانی سور",
    },
    {
      label: "مریوان",
      value: "مریوان",
    },
    {
      label: "موچش",
      value: "موچش",
    },
    {
      label: "یاسوکند",
      value: "یاسوکند",
    },
  ],
  کرمان: [
    {
      label: "اختیارآباد",
      value: "اختیارآباد",
    },
    {
      label: "ارزوئیه",
      value: "ارزوئیه",
    },
    {
      label: "امین شهر",
      value: "امین شهر",
    },
    {
      label: "انار",
      value: "انار",
    },
    {
      label: "اندوهجرد",
      value: "اندوهجرد",
    },
    {
      label: "باغین",
      value: "باغین",
    },
    {
      label: "بافت",
      value: "بافت",
    },
    {
      label: "بردسیر",
      value: "بردسیر",
    },
    {
      label: "بروات",
      value: "بروات",
    },
    {
      label: "بزنجان",
      value: "بزنجان",
    },
    {
      label: "بم",
      value: "بم",
    },
    {
      label: "بهرمان",
      value: "بهرمان",
    },
    {
      label: "پاریز",
      value: "پاریز",
    },
    {
      label: "جبالبارز",
      value: "جبالبارز",
    },
    {
      label: "جوپار",
      value: "جوپار",
    },
    {
      label: "جوزم",
      value: "جوزم",
    },
    {
      label: "جیرفت",
      value: "جیرفت",
    },
    {
      label: "چترود",
      value: "چترود",
    },
    {
      label: "خاتون آباد",
      value: "خاتون آباد",
    },
    {
      label: "خانوک",
      value: "خانوک",
    },
    {
      label: "خورسند",
      value: "خورسند",
    },
    {
      label: "درب بهشت",
      value: "درب بهشت",
    },
    {
      label: "دهج",
      value: "دهج",
    },
    {
      label: "رابر",
      value: "رابر",
    },
    {
      label: "راور",
      value: "راور",
    },
    {
      label: "راین",
      value: "راین",
    },
    {
      label: "رفسنجان",
      value: "رفسنجان",
    },
    {
      label: "رودبار",
      value: "رودبار",
    },
    {
      label: "ریحان شهر",
      value: "ریحان شهر",
    },
    {
      label: "زرند",
      value: "زرند",
    },
    {
      label: "زنگی آباد",
      value: "زنگی آباد",
    },
    {
      label: "زیدآباد",
      value: "زیدآباد",
    },
    {
      label: "سیرجان",
      value: "سیرجان",
    },
    {
      label: "شهداد",
      value: "شهداد",
    },
    {
      label: "شهربابک",
      value: "شهربابک",
    },
    {
      label: "صفائیه",
      value: "صفائیه",
    },
    {
      label: "عنبرآباد",
      value: "عنبرآباد",
    },
    {
      label: "فاریاب",
      value: "فاریاب",
    },
    {
      label: "فهرج",
      value: "فهرج",
    },
    {
      label: "قلعه گنج",
      value: "قلعه گنج",
    },
    {
      label: "کاظم آباد",
      value: "کاظم آباد",
    },
    {
      label: "کرمان",
      value: "کرمان",
    },
    {
      label: "کشکوئیه",
      value: "کشکوئیه",
    },
    {
      label: "کهنوج",
      value: "کهنوج",
    },
    {
      label: "کوهبنان",
      value: "کوهبنان",
    },
    {
      label: "کیانشهر",
      value: "کیانشهر",
    },
    {
      label: "گلباف",
      value: "گلباف",
    },
    {
      label: "گلزار",
      value: "گلزار",
    },
    {
      label: "لاله زار",
      value: "لاله زار",
    },
    {
      label: "ماهان",
      value: "ماهان",
    },
    {
      label: "محمدآباد",
      value: "محمدآباد",
    },
    {
      label: "محی آباد",
      value: "محی آباد",
    },
    {
      label: "مردهک",
      value: "مردهک",
    },
    {
      label: "مس سرچشمه",
      value: "مس سرچشمه",
    },
    {
      label: "منوجان",
      value: "منوجان",
    },
    {
      label: "نجف شهر",
      value: "نجف شهر",
    },
    {
      label: "نرماشیر",
      value: "نرماشیر",
    },
    {
      label: "نظام شهر",
      value: "نظام شهر",
    },
    {
      label: "نگار",
      value: "نگار",
    },
    {
      label: "نودژ",
      value: "نودژ",
    },
    {
      label: "هجدک",
      value: "هجدک",
    },
    {
      label: "یزدان شهر",
      value: "یزدان شهر",
    },
  ],
  کرمانشاه: [
    {
      label: "ازگله",
      value: "ازگله",
    },
    {
      label: "اسلام آباد غرب",
      value: "اسلام آباد غرب",
    },
    {
      label: "باینگان",
      value: "باینگان",
    },
    {
      label: "بیستون",
      value: "بیستون",
    },
    {
      label: "پاوه",
      value: "پاوه",
    },
    {
      label: "تازه آباد",
      value: "تازه آباد",
    },
    {
      label: "جوان رود",
      value: "جوان رود",
    },
    {
      label: "حمیل",
      value: "حمیل",
    },
    {
      label: "ماهیدشت",
      value: "ماهیدشت",
    },
    {
      label: "روانسر",
      value: "روانسر",
    },
    {
      label: "سرپل ذهاب",
      value: "سرپل ذهاب",
    },
    {
      label: "سرمست",
      value: "سرمست",
    },
    {
      label: "سطر",
      value: "سطر",
    },
    {
      label: "سنقر",
      value: "سنقر",
    },
    {
      label: "سومار",
      value: "سومار",
    },
    {
      label: "شاهو",
      value: "شاهو",
    },
    {
      label: "صحنه",
      value: "صحنه",
    },
    {
      label: "قصرشیرین",
      value: "قصرشیرین",
    },
    {
      label: "کرمانشاه",
      value: "کرمانشاه",
    },
    {
      label: "کرندغرب",
      value: "کرندغرب",
    },
    {
      label: "کنگاور",
      value: "کنگاور",
    },
    {
      label: "کوزران",
      value: "کوزران",
    },
    {
      label: "گهواره",
      value: "گهواره",
    },
    {
      label: "گیلانغرب",
      value: "گیلانغرب",
    },
    {
      label: "میان راهان",
      value: "میان راهان",
    },
    {
      label: "نودشه",
      value: "نودشه",
    },
    {
      label: "نوسود",
      value: "نوسود",
    },
    {
      label: "هرسین",
      value: "هرسین",
    },
    {
      label: "هلشی",
      value: "هلشی",
    },
  ],
  "کهگیلویه و بویراحمد": [
    {
      label: "باشت",
      value: "باشت",
    },
    {
      label: "پاتاوه",
      value: "پاتاوه",
    },
    {
      label: "چرام",
      value: "چرام",
    },
    {
      label: "چیتاب",
      value: "چیتاب",
    },
    {
      label: "دهدشت",
      value: "دهدشت",
    },
    {
      label: "دوگنبدان",
      value: "دوگنبدان",
    },
    {
      label: "دیشموک",
      value: "دیشموک",
    },
    {
      label: "سوق",
      value: "سوق",
    },
    {
      label: "سی سخت",
      value: "سی سخت",
    },
    {
      label: "قلعه رئیسی",
      value: "قلعه رئیسی",
    },
    {
      label: "گراب سفلی",
      value: "گراب سفلی",
    },
    {
      label: "لنده",
      value: "لنده",
    },
    {
      label: "لیکک",
      value: "لیکک",
    },
    {
      label: "مادوان",
      value: "مادوان",
    },
    {
      label: "مارگون",
      value: "مارگون",
    },
    {
      label: "یاسوج",
      value: "یاسوج",
    },
  ],
  گلستان: [
    {
      label: "انبارآلوم",
      value: "انبارآلوم",
    },
    {
      label: "اینچه برون",
      value: "اینچه برون",
    },
    {
      label: "آزادشهر",
      value: "آزادشهر",
    },
    {
      label: "آق قلا",
      value: "آق قلا",
    },
    {
      label: "بندرترکمن",
      value: "بندرترکمن",
    },
    {
      label: "بندرگز",
      value: "بندرگز",
    },
    {
      label: "جلین",
      value: "جلین",
    },
    {
      label: "خان ببین",
      value: "خان ببین",
    },
    {
      label: "دلند",
      value: "دلند",
    },
    {
      label: "رامیان",
      value: "رامیان",
    },
    {
      label: "سرخنکلاته",
      value: "سرخنکلاته",
    },
    {
      label: "سیمین شهر",
      value: "سیمین شهر",
    },
    {
      label: "علی آباد کتول",
      value: "علی آباد کتول",
    },
    {
      label: "فاضل آباد",
      value: "فاضل آباد",
    },
    {
      label: "کردکوی",
      value: "کردکوی",
    },
    {
      label: "کلاله",
      value: "کلاله",
    },
    {
      label: "گالیکش",
      value: "گالیکش",
    },
    {
      label: "گرگان",
      value: "گرگان",
    },
    {
      label: "گمیش تپه",
      value: "گمیش تپه",
    },
    {
      label: "گنبدکاووس",
      value: "گنبدکاووس",
    },
    {
      label: "مراوه",
      value: "مراوه",
    },
    {
      label: "مینودشت",
      value: "مینودشت",
    },
    {
      label: "نگین شهر",
      value: "نگین شهر",
    },
    {
      label: "نوده خاندوز",
      value: "نوده خاندوز",
    },
    {
      label: "نوکنده",
      value: "نوکنده",
    },
  ],
  لرستان: [
    {
      label: "ازنا",
      value: "ازنا",
    },
    {
      label: "اشترینان",
      value: "اشترینان",
    },
    {
      label: "الشتر",
      value: "الشتر",
    },
    {
      label: "الیگودرز",
      value: "الیگودرز",
    },
    {
      label: "بروجرد",
      value: "بروجرد",
    },
    {
      label: "پلدختر",
      value: "پلدختر",
    },
    {
      label: "چالانچولان",
      value: "چالانچولان",
    },
    {
      label: "چغلوندی",
      value: "چغلوندی",
    },
    {
      label: "چقابل",
      value: "چقابل",
    },
    {
      label: "خرم آباد",
      value: "خرم آباد",
    },
    {
      label: "درب گنبد",
      value: "درب گنبد",
    },
    {
      label: "دورود",
      value: "دورود",
    },
    {
      label: "زاغه",
      value: "زاغه",
    },
    {
      label: "سپیددشت",
      value: "سپیددشت",
    },
    {
      label: "سراب دوره",
      value: "سراب دوره",
    },
    {
      label: "فیروزآباد",
      value: "فیروزآباد",
    },
    {
      label: "کونانی",
      value: "کونانی",
    },
    {
      label: "کوهدشت",
      value: "کوهدشت",
    },
    {
      label: "گراب",
      value: "گراب",
    },
    {
      label: "معمولان",
      value: "معمولان",
    },
    {
      label: "مومن آباد",
      value: "مومن آباد",
    },
    {
      label: "نورآباد",
      value: "نورآباد",
    },
    {
      label: "ویسیان",
      value: "ویسیان",
    },
  ],
  گیلان: [
    {
      label: "احمدسرگوراب",
      value: "احمدسرگوراب",
    },
    {
      label: "اسالم",
      value: "اسالم",
    },
    {
      label: "اطاقور",
      value: "اطاقور",
    },
    {
      label: "املش",
      value: "املش",
    },
    {
      label: "آستارا",
      value: "آستارا",
    },
    {
      label: "آستانه اشرفیه",
      value: "آستانه اشرفیه",
    },
    {
      label: "بازار جمعه",
      value: "بازار جمعه",
    },
    {
      label: "بره سر",
      value: "بره سر",
    },
    {
      label: "بندرانزلی",
      value: "بندرانزلی",
    },
    {
      label: "پره سر",
      value: "پره سر",
    },
    {
      label: "تالش",
      value: "تالش",
    },
    {
      label: "توتکابن",
      value: "توتکابن",
    },
    {
      label: "جیرنده",
      value: "جیرنده",
    },
    {
      label: "چابکسر",
      value: "چابکسر",
    },
    {
      label: "چاف و چمخاله",
      value: "چاف و چمخاله",
    },
    {
      label: "چوبر",
      value: "چوبر",
    },
    {
      label: "حویق",
      value: "حویق",
    },
    {
      label: "خشکبیجار",
      value: "خشکبیجار",
    },
    {
      label: "خمام",
      value: "خمام",
    },
    {
      label: "دیلمان",
      value: "دیلمان",
    },
    {
      label: "رانکوه",
      value: "رانکوه",
    },
    {
      label: "رحیم آباد",
      value: "رحیم آباد",
    },
    {
      label: "رستم آباد",
      value: "رستم آباد",
    },
    {
      label: "رشت",
      value: "رشت",
    },
    {
      label: "رضوانشهر",
      value: "رضوانشهر",
    },
    {
      label: "رودبار",
      value: "رودبار",
    },
    {
      label: "رودبنه",
      value: "رودبنه",
    },
    {
      label: "رودسر",
      value: "رودسر",
    },
    {
      label: "سنگر",
      value: "سنگر",
    },
    {
      label: "سیاهکل",
      value: "سیاهکل",
    },
    {
      label: "شفت",
      value: "شفت",
    },
    {
      label: "شلمان",
      value: "شلمان",
    },
    {
      label: "صومعه سرا",
      value: "صومعه سرا",
    },
    {
      label: "فومن",
      value: "فومن",
    },
    {
      label: "کلاچای",
      value: "کلاچای",
    },
    {
      label: "کوچصفهان",
      value: "کوچصفهان",
    },
    {
      label: "کومله",
      value: "کومله",
    },
    {
      label: "کیاشهر",
      value: "کیاشهر",
    },
    {
      label: "گوراب زرمیخ",
      value: "گوراب زرمیخ",
    },
    {
      label: "لاهیجان",
      value: "لاهیجان",
    },
    {
      label: "لشت نشا",
      value: "لشت نشا",
    },
    {
      label: "لنگرود",
      value: "لنگرود",
    },
    {
      label: "لوشان",
      value: "لوشان",
    },
    {
      label: "لولمان",
      value: "لولمان",
    },
    {
      label: "لوندویل",
      value: "لوندویل",
    },
    {
      label: "لیسار",
      value: "لیسار",
    },
    {
      label: "ماسال",
      value: "ماسال",
    },
    {
      label: "ماسوله",
      value: "ماسوله",
    },
    {
      label: "مرجقل",
      value: "مرجقل",
    },
    {
      label: "منجیل",
      value: "منجیل",
    },
    {
      label: "واجارگاه",
      value: "واجارگاه",
    },
    {
      label: "زیباکنار",
      value: "زیباکنار",
    },
    {
      label: "پیربازار",
      value: "پیربازار",
    },
  ],
  مازندران: [
    {
      label: "امیرکلا",
      value: "امیرکلا",
    },
    {
      label: "ایزدشهر",
      value: "ایزدشهر",
    },
    {
      label: "آلاشت",
      value: "آلاشت",
    },
    {
      label: "آمل",
      value: "آمل",
    },
    {
      label: "بابل",
      value: "بابل",
    },
    {
      label: "بابلسر",
      value: "بابلسر",
    },
    {
      label: "بلده",
      value: "بلده",
    },
    {
      label: "بهشهر",
      value: "بهشهر",
    },
    {
      label: "بهنمیر",
      value: "بهنمیر",
    },
    {
      label: "پل سفید",
      value: "پل سفید",
    },
    {
      label: "تنکابن",
      value: "تنکابن",
    },
    {
      label: "جویبار",
      value: "جویبار",
    },
    {
      label: "چالوس",
      value: "چالوس",
    },
    {
      label: "چمستان",
      value: "چمستان",
    },
    {
      label: "خرم آباد",
      value: "خرم آباد",
    },
    {
      label: "خلیل شهر",
      value: "خلیل شهر",
    },
    {
      label: "خوش رودپی",
      value: "خوش رودپی",
    },
    {
      label: "دابودشت",
      value: "دابودشت",
    },
    {
      label: "رامسر",
      value: "رامسر",
    },
    {
      label: "رستمکلا",
      value: "رستمکلا",
    },
    {
      label: "رویان",
      value: "رویان",
    },
    {
      label: "رینه",
      value: "رینه",
    },
    {
      label: "زرگرمحله",
      value: "زرگرمحله",
    },
    {
      label: "زیرآب",
      value: "زیرآب",
    },
    {
      label: "ساری",
      value: "ساری",
    },
    {
      label: "سرخرود",
      value: "سرخرود",
    },
    {
      label: "سلمان شهر",
      value: "سلمان شهر",
    },
    {
      label: "سورک",
      value: "سورک",
    },
    {
      label: "شیرگاه",
      value: "شیرگاه",
    },
    {
      label: "شیرود",
      value: "شیرود",
    },
    {
      label: "عباس آباد",
      value: "عباس آباد",
    },
    {
      label: "فریدونکنار",
      value: "فریدونکنار",
    },
    {
      label: "فریم",
      value: "فریم",
    },
    {
      label: "قائم شهر",
      value: "قائم شهر",
    },
    {
      label: "کتالم",
      value: "کتالم",
    },
    {
      label: "کلارآباد",
      value: "کلارآباد",
    },
    {
      label: "کلاردشت",
      value: "کلاردشت",
    },
    {
      label: "کله بست",
      value: "کله بست",
    },
    {
      label: "کوهی خیل",
      value: "کوهی خیل",
    },
    {
      label: "کیاسر",
      value: "کیاسر",
    },
    {
      label: "کیاکلا",
      value: "کیاکلا",
    },
    {
      label: "گتاب",
      value: "گتاب",
    },
    {
      label: "گزنک",
      value: "گزنک",
    },
    {
      label: "گلوگاه",
      value: "گلوگاه",
    },
    {
      label: "محمودآباد",
      value: "محمودآباد",
    },
    {
      label: "مرزن آباد",
      value: "مرزن آباد",
    },
    {
      label: "مرزیکلا",
      value: "مرزیکلا",
    },
    {
      label: "نشتارود",
      value: "نشتارود",
    },
    {
      label: "نکا",
      value: "نکا",
    },
    {
      label: "نور",
      value: "نور",
    },
    {
      label: "نوشهر",
      value: "نوشهر",
    },
    {
      label: "سادات شهر",
      value: "سادات شهر",
    },
  ],
  مرکزی: [
    {
      label: "اراک",
      value: "اراک",
    },
    {
      label: "آستانه",
      value: "آستانه",
    },
    {
      label: "آشتیان",
      value: "آشتیان",
    },
    {
      label: "پرندک",
      value: "پرندک",
    },
    {
      label: "تفرش",
      value: "تفرش",
    },
    {
      label: "توره",
      value: "توره",
    },
    {
      label: "جاورسیان",
      value: "جاورسیان",
    },
    {
      label: "خشکرود",
      value: "خشکرود",
    },
    {
      label: "خمین",
      value: "خمین",
    },
    {
      label: "خنداب",
      value: "خنداب",
    },
    {
      label: "داودآباد",
      value: "داودآباد",
    },
    {
      label: "دلیجان",
      value: "دلیجان",
    },
    {
      label: "رازقان",
      value: "رازقان",
    },
    {
      label: "زاویه",
      value: "زاویه",
    },
    {
      label: "ساروق",
      value: "ساروق",
    },
    {
      label: "ساوه",
      value: "ساوه",
    },
    {
      label: "سنجان",
      value: "سنجان",
    },
    {
      label: "شازند",
      value: "شازند",
    },
    {
      label: "غرق آباد",
      value: "غرق آباد",
    },
    {
      label: "فرمهین",
      value: "فرمهین",
    },
    {
      label: "قورچی باشی",
      value: "قورچی باشی",
    },
    {
      label: "کرهرود",
      value: "کرهرود",
    },
    {
      label: "کمیجان",
      value: "کمیجان",
    },
    {
      label: "مامونیه",
      value: "مامونیه",
    },
    {
      label: "محلات",
      value: "محلات",
    },
    {
      label: "مهاجران",
      value: "مهاجران",
    },
    {
      label: "میلاجرد",
      value: "میلاجرد",
    },
    {
      label: "نراق",
      value: "نراق",
    },
    {
      label: "نوبران",
      value: "نوبران",
    },
    {
      label: "نیمور",
      value: "نیمور",
    },
    {
      label: "هندودر",
      value: "هندودر",
    },
  ],
  هرمزگان: [
    {
      label: "ابوموسی",
      value: "ابوموسی",
    },
    {
      label: "بستک",
      value: "بستک",
    },
    {
      label: "بندرجاسک",
      value: "بندرجاسک",
    },
    {
      label: "بندرچارک",
      value: "بندرچارک",
    },
    {
      label: "بندرخمیر",
      value: "بندرخمیر",
    },
    {
      label: "بندرعباس",
      value: "بندرعباس",
    },
    {
      label: "بندرلنگه",
      value: "بندرلنگه",
    },
    {
      label: "بیکا",
      value: "بیکا",
    },
    {
      label: "پارسیان",
      value: "پارسیان",
    },
    {
      label: "تخت",
      value: "تخت",
    },
    {
      label: "جناح",
      value: "جناح",
    },
    {
      label: "حاجی آباد",
      value: "حاجی آباد",
    },
    {
      label: "درگهان",
      value: "درگهان",
    },
    {
      label: "دهبارز",
      value: "دهبارز",
    },
    {
      label: "رویدر",
      value: "رویدر",
    },
    {
      label: "زیارتعلی",
      value: "زیارتعلی",
    },
    {
      label: "سردشت",
      value: "سردشت",
    },
    {
      label: "سندرک",
      value: "سندرک",
    },
    {
      label: "سوزا",
      value: "سوزا",
    },
    {
      label: "سیریک",
      value: "سیریک",
    },
    {
      label: "فارغان",
      value: "فارغان",
    },
    {
      label: "فین",
      value: "فین",
    },
    {
      label: "قشم",
      value: "قشم",
    },
    {
      label: "قلعه قاضی",
      value: "قلعه قاضی",
    },
    {
      label: "کنگ",
      value: "کنگ",
    },
    {
      label: "کوشکنار",
      value: "کوشکنار",
    },
    {
      label: "کیش",
      value: "کیش",
    },
    {
      label: "گوهران",
      value: "گوهران",
    },
    {
      label: "میناب",
      value: "میناب",
    },
    {
      label: "هرمز",
      value: "هرمز",
    },
    {
      label: "هشتبندی",
      value: "هشتبندی",
    },
  ],
  همدان: [
    {
      label: "ازندریان",
      value: "ازندریان",
    },
    {
      label: "اسدآباد",
      value: "اسدآباد",
    },
    {
      label: "برزول",
      value: "برزول",
    },
    {
      label: "بهار",
      value: "بهار",
    },
    {
      label: "تویسرکان",
      value: "تویسرکان",
    },
    {
      label: "جورقان",
      value: "جورقان",
    },
    {
      label: "جوکار",
      value: "جوکار",
    },
    {
      label: "دمق",
      value: "دمق",
    },
    {
      label: "رزن",
      value: "رزن",
    },
    {
      label: "زنگنه",
      value: "زنگنه",
    },
    {
      label: "سامن",
      value: "سامن",
    },
    {
      label: "سرکان",
      value: "سرکان",
    },
    {
      label: "شیرین سو",
      value: "شیرین سو",
    },
    {
      label: "صالح آباد",
      value: "صالح آباد",
    },
    {
      label: "فامنین",
      value: "فامنین",
    },
    {
      label: "فرسفج",
      value: "فرسفج",
    },
    {
      label: "فیروزان",
      value: "فیروزان",
    },
    {
      label: "قروه درجزین",
      value: "قروه درجزین",
    },
    {
      label: "قهاوند",
      value: "قهاوند",
    },
    {
      label: "کبودر آهنگ",
      value: "کبودر آهنگ",
    },
    {
      label: "گل تپه",
      value: "گل تپه",
    },
    {
      label: "گیان",
      value: "گیان",
    },
    {
      label: "لالجین",
      value: "لالجین",
    },
    {
      label: "مریانج",
      value: "مریانج",
    },
    {
      label: "ملایر",
      value: "ملایر",
    },
    {
      label: "نهاوند",
      value: "نهاوند",
    },
    {
      label: "همدان",
      value: "همدان",
    },
  ],
  یزد: [
    {
      label: "ابرکوه",
      value: "ابرکوه",
    },
    {
      label: "احمدآباد",
      value: "احمدآباد",
    },
    {
      label: "اردکان",
      value: "اردکان",
    },
    {
      label: "اشکذر",
      value: "اشکذر",
    },
    {
      label: "بافق",
      value: "بافق",
    },
    {
      label: "بفروئیه",
      value: "بفروئیه",
    },
    {
      label: "بهاباد",
      value: "بهاباد",
    },
    {
      label: "تفت",
      value: "تفت",
    },
    {
      label: "حمیدیا",
      value: "حمیدیا",
    },
    {
      label: "خضرآباد",
      value: "خضرآباد",
    },
    {
      label: "دیهوک",
      value: "دیهوک",
    },
    {
      label: "زارچ",
      value: "زارچ",
    },
    {
      label: "شاهدیه",
      value: "شاهدیه",
    },
    {
      label: "طبس",
      value: "طبس",
    },
    {
      label: "عقدا",
      value: "عقدا",
    },
    {
      label: "مروست",
      value: "مروست",
    },
    {
      label: "مهردشت",
      value: "مهردشت",
    },
    {
      label: "مهریز",
      value: "مهریز",
    },
    {
      label: "میبد",
      value: "میبد",
    },
    {
      label: "ندوشن",
      value: "ندوشن",
    },
    {
      label: "نیر",
      value: "نیر",
    },
    {
      label: "هرات",
      value: "هرات",
    },
    {
      label: "یزد",
      value: "یزد",
    },
    {
      label: "رضوانشهر",
      value: "رضوانشهر",
    },
  ],
};

export default IranCities;
