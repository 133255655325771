/** Convert Persian and Arabic numbers to English numbers */
export default function convertPersianNumbersToEnglish(number) {
  const persianNumbers = [
    /۰/g,
    /۱/g,
    /۲/g,
    /۳/g,
    /۴/g,
    /۵/g,
    /۶/g,
    /۷/g,
    /۸/g,
    /۹/g,
  ];
  const arabicNumbers = [
    /٠/g,
    /١/g,
    /٢/g,
    /٣/g,
    /٤/g,
    /٥/g,
    /٦/g,
    /٧/g,
    /٨/g,
    /٩/g,
  ];

  const fixNumbers = () => {
    if (typeof number === "string") {
      for (var i = 0; i < 10; i++) {
        number = number
          .replace(persianNumbers[i], i)
          .replace(arabicNumbers[i], i);
      }
    }
    return number;
  };
  return fixNumbers();
}
