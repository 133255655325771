import calculateEventStartTime from "./calculateEventStartTime";

/** Convert Date object to Persian locale string */
export function toJalaliDateString(date) {
  return date?.toLocaleDateString("fa", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

export function toJalaliDateTimeString(date) {
  return date?.toLocaleString("fa", {
    hour12: false,
    minute: "2-digit",
    hour: "2-digit",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

export function GetTime(date) {
  return (date || new Date()).toLocaleTimeString("en", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function getJalaliWeekDayName(date) {
  return date.toLocaleDateString("fa", {
    weekday: "long",
  });
}

/** Convert Date object to Persian locale string */
export function formatGDate(date) {
  const year = date.toLocaleDateString("en", { year: "numeric" });
  const month = date.toLocaleDateString("en", { month: "2-digit" });
  const day = date.toLocaleDateString("en", { day: "2-digit" });
  return `${year}-${month}-${day}`;
}

export function passDateFormat(date) {
  // Calculate the time difference in milliseconds
  const timeDiff = new Date() - date;

  // Convert milliseconds to seconds, minutes, hours, and days
  const seconds = Math.floor(timeDiff / 1000);
  if (seconds < 60) return "لحظاتی پیش";
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) return `${minutes} دقیقه پیش`;
  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours} ساعت پیش`;
  const days = Math.floor(hours / 24);
  if (days == 1) return `دیروز`;
  // if (days < 24) return `${days} روز پیش`;
  return date.toLocaleDateString("fa", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });
}
/**
 * Checks the received data of the event and extracts information such as the number of days of absence,
 * the number of days attended and the number of remaining days in addition to the list of all dates of the event.
 */
export function parseEventData(eventData, serverCurrentDate) {
  const isEventClosed = eventData.is_archived === true;
  const startDate = new Date(eventData.start_date);
  const endDate = new Date(eventData.end_date);
  const currentServerDate = new Date(serverCurrentDate);
  const currentDate = new Date(
    isEventClosed ? eventData.end_date : serverCurrentDate
  );
  const currentDateString = formatGDate(currentDate);
  const presentDatesList = [
    ...eventData.participations.map((i) => i.datetime),
    ...(eventData.compensations?.map((i) => i.date_to_be_compensated) || []),
  ].map((d) => formatGDate(new Date(d)));

  let todayIndex = -1;
  let absentDaysNumber = 0;
  let remainDaysNumber = 0;
  const datesList = [];
  let isAttendToday = false;
  const untilStart = calculateEventStartTime(eventData, currentServerDate);
  const statistics = eventData.statistics;

  let index = 0;
  let dateIndex = startDate;
  while (dateIndex < endDate) {
    const dateIndexString = formatGDate(dateIndex);

    const dateInfo = {
      gDate: dateIndexString,
      jDate: toJalaliDateString(dateIndex),
      jWeekDay: getJalaliWeekDayName(dateIndex),
      isToday: dateIndexString === currentDateString,
      isPassed: dateIndex < currentDate,
      isPresent: presentDatesList.includes(dateIndexString),
      participants:
        eventData.publication_state === "public"
          ? statistics[dateIndexString]
          : null,
    };

    if (dateInfo.isPassed || dateInfo.isToday) {
      if (!dateInfo.isPresent) {
        if (dateInfo.isToday) {
          ++remainDaysNumber;
        } else {
          ++absentDaysNumber;
        }
      } else if (dateInfo.isToday) {
        isAttendToday = true;
      }
    } else {
      ++remainDaysNumber;
    }

    if (!isEventClosed && dateInfo.isToday) {
      todayIndex = index;
    }

    datesList.push(dateInfo);
    dateIndex.setDate(dateIndex.getDate() + 1);
    ++index;
  }

  let isOnline = true;
  if (eventData.daily_participation_time) {
    const dptParts = eventData.daily_participation_time.split(":");

    const currentHours = currentServerDate.getHours();
    const dptHours = parseInt(dptParts[0]);
    const currentMinutes = currentServerDate.getMinutes();
    const dptMinutes = parseInt(dptParts[1]);

    if (currentHours > dptHours) {
      isOnline = false;
    } else if (currentHours === dptHours) {
      if (currentMinutes >= dptMinutes) {
        isOnline = false;
      }
    }
  }

  return {
    eventId: eventData.event_id,
    isOwner: eventData.is_owner,
    publicationState: eventData.publication_state,
    isClosed: eventData.is_archived,
    isPrivate: false, //eventData.is_private,
    isOver: eventData.is_over,
    todayGDate: currentDateString,
    statistics,
    todayIndex,
    attendedDaysNumber: presentDatesList.length,
    absentDaysNumber,
    remainDaysNumber,
    datesList,
    isAttendToday,
    isOnline,
    untilStart,
    passDateFormat,
    eventData,
  };
}
