import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";

const EsteghfarBookOrderPresent = () => {
  return (
    <Flex direction="column" gap={25}>
      <Text align="center">معرفی طرح بزرگ استغفار</Text>
    </Flex>
  );
};

export default EsteghfarBookOrderPresent;
