import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  VStack,
} from "@chakra-ui/react";
import React from "react";

interface Props {
  formProps: {
    amount: {
      value: string;
      isInvalid: boolean;
    };
    name: {
      value: string;
      isInvalid: boolean;
    };
    cartNumber: {
      value: string;
      isInvalid: boolean;
    };
    referenceNumber: {
      value: string;
      isInvalid: boolean;
    };
    description: {
      value: string;
      isInvalid: boolean;
    };
  };
  handleFormProps: (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => void;
}

const ManualPayment: React.FC<Props> = ({ formProps, handleFormProps }) => {
  return (
    <VStack width="100%" spacing={6}>
      <FormControl isInvalid={formProps.amount.isInvalid}>
        <FormLabel fontWeight={800}>مبلغ:</FormLabel>
        <Input
          value={formProps.amount.value}
          onChange={(e) => handleFormProps(e, "amount")}
          placeholder="لطفا مبلغ واریزی را وارد کنید"
          type="number"
        />
        {formProps.amount.isInvalid && (
          <FormErrorMessage>وارد کردن مبلغ الزامی است.</FormErrorMessage>
        )}
      </FormControl>

      <FormControl isInvalid={formProps.name.isInvalid}>
        <FormLabel fontWeight={800}>نام واریز کننده:</FormLabel>
        <Input
          value={formProps.name.value}
          onChange={(e) => handleFormProps(e, "name")}
          placeholder="لطفا نام واریز کننده را وارد کنید"
        />
        {formProps.name.isInvalid && (
          <FormErrorMessage>
            وارد کردن نام واریز کننده الزامی است.
          </FormErrorMessage>
        )}
      </FormControl>

      <FormControl isInvalid={formProps.cartNumber.isInvalid}>
        <FormLabel fontWeight={800}>شماره کارت مبدأ:</FormLabel>
        <Input
          value={formProps.cartNumber.value}
          onChange={(e) => handleFormProps(e, "cartNumber")}
          placeholder="لطفا شماره کارت مبدأ را وارد کنید"
          type="number"
        />
        {formProps.cartNumber.isInvalid && (
          <FormErrorMessage>
            وارد کردن شماره کارت مبدأ الزامی است.
          </FormErrorMessage>
        )}
      </FormControl>

      <FormControl isInvalid={formProps.referenceNumber.isInvalid}>
        <FormLabel fontWeight={800}>شماره مرجع:</FormLabel>
        <Input
          value={formProps.referenceNumber.value}
          onChange={(e) => handleFormProps(e, "referenceNumber")}
          placeholder="لطفا شماره مرجع را وارد کنید"
          type="number"
        />
        {formProps.referenceNumber.isInvalid && (
          <FormErrorMessage>وارد کردن شماره مرجع الزامی است.</FormErrorMessage>
        )}
      </FormControl>

      <FormControl isInvalid={formProps.description.isInvalid}>
        <FormLabel fontWeight={800}>توضیحات:</FormLabel>
        <Input
          value={formProps.description.value}
          onChange={(e) => handleFormProps(e, "description")}
          placeholder="لطفا توضیحات را وارد کنید"
        />
        {formProps.description.isInvalid && (
          <FormErrorMessage>وارد کردن توضیحات الزامی است.</FormErrorMessage>
        )}
      </FormControl>
    </VStack>
  );
};

export default ManualPayment;
